
         @import "config.scss";
      
.container {
   height: 100%;
   overflow: scroll;

   .close {
      @extend %modalButtonClose;
      justify-content: flex-end;
      background-color: rgba(255, 255, 255, 0.93);
      width: 100%;

      button {
         @extend %removeButtonStyle;
         padding: 20px 10px;
         margin-right: 20px;
      }
   }

   .content {
      padding: 40px 30px 60px 30px;

      .title {
         @extend %headline-6;
         display: block;
         margin: 25px 0;
      }
      h1 {
         font-size: 16px !important;
         margin-bottom: 10px !important;
      }
   }
}

@include media('>=tablet') {
   .container {
      overflow: unset;

      .close {
         display: none;
      }

      .content {
         padding: 0;

         .title {
            margin: 0;
         }
      }
   }
}
