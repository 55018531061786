
         @import "config.scss";
      
.container {
   @extend %container;
   @extend %containerBreakpoint;
   margin-top: 15px;

   display: grid;
   grid-template-rows: 50px auto;

   .filter,
   .sort {
      display: none;
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      z-index: 2;
   }
}

// On mobile remove paddings of container
@include media('<=tablet') {
   .container {
      padding: 0;
   }
   .productList {
      padding: 15px 10px;
   }
}

// Desktop
@include media('>=tablet') {
   .container {
      grid-template-columns: 200px 1fr;
      grid-template-rows: 40px auto;
      grid-column-gap: 30px;
      padding-bottom: 100px;

      .filter,
      .sort {
         position: relative;
         width: auto;
         z-index: 1;
      }
      .filter {
         grid-row: 1/3;
         display: block;
         height: 100%;
      }
      .sort {
         display: block;
         justify-self: end;
      }
   }
}
