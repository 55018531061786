
         @import "config.scss";
      
.container {
   @extend %removeButtonStyle;
   display: flex;
   align-items: center;
   padding: 5px;
   width: 100%;
   cursor: pointer;

   &:active {
      background-color: $color-black-100;
   }
   .name {
      @extend %body-2;
      margin-left: 10px;
   }

   .icon {
      display: flex;
      position: relative;
      color: var(--color-secondary);

      .tickFill {
         display: none;
         position: absolute;
         background-color: var(--color-primary-contrast);
         width: 13px;
         height: 13px;
         z-index: -1;
         top: 3px;
         margin: auto;
         left: 0;
         right: 0;
      }

      &--checked {
         color: var(--color-primary);

         .tickFill {
            display: block;
         }
      }
   }
}
