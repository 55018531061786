
         @import "config.scss";
      
.container {
   display: grid;
   grid-template-columns: auto;
   grid-template-rows: auto auto 100px;

   .sortFilterButtons,
   .chipList {
      align-self: center;
      margin: 20px 0;
   }

   .sortFilterButtons {
      justify-self: center;
   }

   .sortList {
      display: none;
   }

   .chipList {
      justify-self: left;
   }

   .productList {
      display: grid;
      grid-template-rows: auto;
      /* autoprefixer: ignore next */
      grid-template-columns: repeat(auto-fill, minmax(155px, 1fr));
      grid-gap: 10px;

      @supports not (
         grid-template-columns: repeat(auto-fill, minmax(155px, 1fr))
      ) {
         @include media('>400px') {
            grid-template-rows: auto;
            grid-template-columns: repeat(2, minmax(155px, 1fr));
         }

         @include media('>720px') {
            grid-template-rows: auto;
            grid-template-columns: repeat(3, minmax(155px, 1fr));
         }

         @include media('>1280px') {
            grid-template-rows: auto;
            grid-template-columns: repeat(4, minmax(155px, 1fr));
         }
      }
   }
   .pagination {
      align-self: center;
      justify-self: center;
   }
}

// Desktop
@include media('>desktop') {
   .container {
      grid-template-columns: auto;
      grid-template-rows: 60px auto 120px;
      padding: 0;

      .sortFilterButtons {
         display: none;
      }

      .sortList {
         align-self: center;
         justify-self: right;
         display: block;
      }

      .chipList {
         margin-bottom: 20px;
      }

      .productList {
         /* autoprefixer: off */
         grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
      }
   }
}
