
         @import "config.scss";
      
.container {
   display: flex;
   @include borderRadius($radius-pagination);
   border: $border-pagination solid $color-pagination-border;

   .item {
      @extend %flexCenter;
      border-color: $color-pagination-border;
      color: $color-pagination-item;
      height: 38px;
      width: 38px;

      &:hover,
      &:active,
      &:focus {
         background-color: $color-on-pagination-item-bg;
      }

      &--active {
         background-color: $color-pagination-item-bg--active;
         border-color: $color-pagination-item-border--active;
         color: $color-pagination-item--active;

         &:hover,
         &:active,
         &:focus {
            background-color: $color-on-pagination-item--active;
         }
      }

      &--disabled {
         @include opacity(0.2);
         background-color: $color-pagination-item--disabled;

         &:hover,
         &:active,
         &:focus {
            background-color: $color-pagination-item--disabled;
         }
      }

      &--dots {
         &:hover {
            background-color: unset;
         }
      }
   }
}
