
         @import "config.scss";
      
.container {
   height: 100%;
   overflow: scroll;

   .btnAction {
      @extend %removeButtonStyle;
      @extend %body-1;
      display: none;
   }

   .close {
      @extend %modalButtonClose;
      justify-content: flex-end;
      background-color: rgba(255, 255, 255, 0.93);
      width: 100%;

      button {
         @extend %removeButtonStyle;
         padding: 20px 10px;
         margin-right: 20px;
      }
   }

   .content {
      padding: 45px 30px 60px 30px;

      .title {
         @extend %headline-6;
         display: block;
         margin: 25px 0;
      }
   }
}

@include media('>=tablet') {
   .container {
      overflow: unset;

      .btnAction {
         display: flex;
         align-items: center;
         justify-content: space-between;
         font-weight: 500;
         width: 200px;
         padding: 10px 20px;
         box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px 0;
         background-color: $color-grey-200;
         border-radius: 5px;
         cursor: pointer;

         &:active {
            box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
         }
      }

      .close,
      .content {
         display: none;
      }

      .content {
         position: absolute;
         background-color: white;
         box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px 0;
         border-radius: 4px;
         border: 1px solid $color-grey-200;
         padding: 5px 0;
         margin-top: 5px;
         width: 100%;

         &--visible {
            display: block;
         }

         .title {
            display: none;
         }
      }
   }
}
