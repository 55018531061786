
         @import "config.scss";
      
.wrapperButtons {
   position: sticky;
   top: 0;
   display: flex;
   align-items: center;
   height: 50px;
   box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px 0;
   background-color: black;
   z-index: 1;

   .button {
      @extend %button;
      @extend %removeButtonStyle;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1;
      height: 100%;

      &:active {
         background-color: rgba(0, 0, 0, 0.05);
      }

      span {
         margin-left: 10px;
      }
   }
}
.title {
   @extend %headline-6;
   margin-top: 25px;
   font-size: 16px !important;
   text-align: center;
}
.visible {
   display: block !important;
}

@include media('>=tablet') {
   .wrapperButtons {
      display: none;
   }
}
