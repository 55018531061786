
         @import "config.scss";
      
.container {
   display: flex;

   ul {
      display: flex;
      flex-wrap: wrap;

      li {
         margin-right: 10px;
         margin-bottom: 10px;
      }
   }

   .chip {
      @extend %removeButtonStyle;
      @extend %body-2;
      display: flex;
      align-items: center;
      color: black;
      background-color: $color-grey-100;
      padding: 5px 10px;
      border-radius: 50px;
      cursor: pointer;

      &:hover {
         text-decoration: line-through;
         background-color: $color-grey-200;
      }
   }
}
