
         @import "config.scss";
      
.container {
   width: 23%;

   margin: 0px 5px 5px 0px;

   button {
      @extend %removeButtonStyle;
      background-color: $color-black-100;
      padding: 15px;
      width: 100%;
      text-align: center;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
         background-color: $color-grey-200;
      }
      .name {
         @extend %body-2;
      }
   }
}

.checked {
   button {
      background-color: $color-black-300;
      color: white;
   }
}

@include media('>=tablet') {
   .container {
      width: 30%;
   }
}
