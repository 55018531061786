
         @import "config.scss";
      
.container {
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: auto;
   height: fit-content;
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   max-width: 1440px;
   min-height: 100px;

   .list {
      z-index: 1;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      padding: 25px;

      li {
         margin: 10px;
      }
   }

   .chip {
      @extend %body-1;
      display: block;
      color: black;
      background-color: white;
      padding: 5px 10px;
      border: 1px solid #eee;
      border-radius: 50px;
      box-shadow: rgba(0, 0, 0, 0.08) 0 2px 4px 0;
   }

   .mobile {
      display: block;
   }
   .desktop {
      display: none;
   }
}

@include media('>tablet') {
   .container {
      min-height: 150px;

      .list {
         margin: 40px;
      }

      .mobile {
         display: none;
      }
      .desktop {
         display: block;
      }
   }
}
