
         @import "config.scss";
      
.container {
   margin: 25px 0;

   &:hover {
      background-color: $color-black-100;
   }

   button {
      @extend %removeButtonStyle;
      @extend %body-1;
      display: flex;
      align-items: center;
      width: 100%;
      color: $color-blue-200;
      padding: 10px 0;
      border-bottom: 1px solid $color-black-100;
      cursor: pointer;

      svg {
         margin-right: 10px;
      }
   }
}

@include media('>=tablet') {
   .container {
      margin: 0;

      button {
         padding: 15px;
         cursor: pointer;
         border-bottom: unset;
      }

      &--active {
         button {
            border-left: 4px solid $color-blue-100;
            padding: 15px 10px;
         }
      }
   }
}
