
         @import "config.scss";
      
.container {
   min-height: 490px;
}
.wrapper {
   display: flex;
   @extend %boxShadowProductItem;
   flex-direction: column;
   @include borderRadius($radius-product-item);
   background-color: $color-product-item-bg;
   border: $border-product-item solid $color-product-item-border;
   color: $color-product-item;
   overflow: hidden;
   position: relative;
   width: 100%;

   &:hover,
   &:active,
   &:focus {
      @extend %boxShadowOnProductItem;
      @include transition(all 250ms ease-out 0s);
      @include borderRadius($radius-product-item);
      color: $color-product-item;
   }

   .discountBrand {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      height: 50px;

      .discount,
      .brand {
         align-self: center;
      }

      .discount {
         grid-column: 1/2;
         justify-self: start;

         span {
            @extend %caption;
            color: #ffffff;
            font-weight: 700;
            padding: 8px 4px;
            background-color: #ff0000;
            display: inline-block;
         }
      }

      .brand {
         position: relative;
         width: 80px;
         height: 40px;
         grid-column: 2/3;
         justify-self: end;
      }
   }

   .imageWrapper {
      @extend %flexCenter;
      position: relative;
      text-align: center;
      min-height: 250px;
      overflow: hidden;
      img {
         object-fit: cover;
         min-height: auto !important;
         width: 100% !important;
         height: auto !important;
      }

      .image {
         transition: visibility 0s, opacity 0.25s linear;
         position: absolute;
         width: 100%;
         pointer-events: none;

         &--hide {
            @include opacity(0);
         }
      }
   }

   @keyframes load {
      0% {
         background-color: rgb(255, 255, 255);
      }
      50% {
         background-color: rgb(230, 230, 230);
      }
      100% {
         background-color: rgb(255, 255, 255);
      }
   }

   .placeholderBlur {
      transition: all 250ms ease-out 1s;
      position: absolute;
      top: 50px;
      height: 250px;
      width: 100%;
   }

   .details {
      margin: 5px;

      .genderFeatured,
      .name,
      .price,
      .installments,
      .freeShipping {
         margin: 8px 0;
      }

      .installments,
      .freeShipping {
         height: 25px;
      }

      .genderFeatured {
         display: flex;
         align-items: center;
         justify-content: space-between;
         height: 20px;
         .gender {
            @extend %label;
         }
      }

      .name {
         display: block;
         overflow: hidden !important;
         height: 48px;

         h2 {
            @extend %product-item-name;

            &:hover,
            &:active,
            &:focus {
               text-decoration: none;
            }
         }
      }

      .price {
         display: flex;
         justify-content: space-between;
         align-items: baseline;
         .currentPrice {
            @extend %product-item-price;
         }
         .originalPrice {
            @extend %product-item-original-price;
            text-decoration: line-through;
         }
      }

      .installments {
         display: flex;
         @extend %installments;
         align-items: center;
         color: $color-installments;
      }

      .freeShipping {
         display: flex;
         @extend %free-shipping;
         align-items: center;
         color: $color-free-shipping;
      }
   }

   .button {
      border-radius: 0;
      min-width: 100%;
      margin: 0;
   }
}
